import CelebrationIcon from '@mui/icons-material/Celebration';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import SellIcon from '@mui/icons-material/Sell';
import {
  isPrimeDay,
  isLaborDay,
  isBeforeLaborDay,
  isBeforePrimeDay,
  isBeforeCyberMonday,
  getDaysBetweenNowAndDate,
  displayTwelveDaysOfDeals,
  isCyberMonday,
  displayShippingCutoffMessage,
  isBlackFridayDealsWeek,
  isBlackFriday,
  isBeforeBlackFriday
} from '@utils/index';
import config from '@configFile';
import {
  customCyberMondayLoadingMessages,
  customBlackFridayLoadingMessages,
  lastMinuteLoadingMessages,
  holidayLoadingMessages
} from '@utils/events/loadingMessages';
import { EventStrObj } from '@types';

import colors from './colors';

const currentYear = new Date().getFullYear();

const primeLabel = isPrimeDay() ? 'Prime Day' : 'Early Prime Day';

const blackFridayDeal = `
<div style="font-style:italic;font-size: 14px;line-height:18px;">
<p style="margin-bottom:12px">It's Black Friday, and the biggest shopping day of the year is here! Head over to <a href="${config.SITE_URL}/black-friday" target="_blank">Amazon's Black Friday Deals</a> to find unbeatable discounts across every category—tech, home, fashion, beauty, and so much more.</p>

<p style="margin-bottom:12px">Today only, Amazon is dropping massive savings on thousands of products, with many deals available for a limited time or while supplies last. Be sure to snag those Lightning Deals before they sell out, and keep refreshing for new deals throughout the day!</p>

<p style="margin-bottom:12px">We’ve handpicked the hottest Black Friday offers to make your shopping easier. Don’t miss out—grab your favorites before they’re gone and kick off the holiday season with incredible savings!</p>

<p style="margin-bottom:12px"><a href="${config.SITE_URL}/black-friday" target="_blank">Shop Today's Best Black Friday Deals</a></p>
</div>
`;

export const getCurrentDealEventStrObj = (): EventStrObj | null => {
  if (isPrimeDay() || config.IS_PRIME_RUN_UP) {
    const overrideEmailEventLink = isPrimeDay()
      ? `https://www.jungle.deals/primeDay`
      : undefined;
    return {
      label: primeLabel,
      value: 'PRIME_DAY',
      showCountdown: true,
      showBannerConfetti: isPrimeDay(),
      eventStartDate: config.DATES.PRIME_DAY_START,
      isEarlySale: config.SHOW_EARLY_DEALS || !isPrimeDay(),
      listingMode: `PRIME_DAY_${currentYear}`,
      dealHighlightColor: colors.jdbPurple,
      chipSelectedColor: colors.primeSelected,
      Icon: CelebrationIcon,
      iconName: 'CelebrationIcon',
      prefix: isPrimeDay() ? 'Prime Day' : 'Early Prime Day',
      recheckStagedDeals: isPrimeDay(),
      showSitePrefix: true,
      shouldShowDeals: true,
      shouldOverrideCollageDailyImage: !!isPrimeDay(),
      shouldShowAPINotice: isPrimeDay(),
      overrideEmailEventLink,
      showNavLinkOption: isPrimeDay(),
      navLinkTitle: 'Prime Day',
      navLinkHref: '/primeday',
      showRibbonsAtFullOpacity: isPrimeDay(),
      overrideRibbonTitle: isPrimeDay()
        ? 'This is an official Prime Day deal'
        : 'This is an official Prime Day Early deal'
    };
  }

  if (isLaborDay()) {
    return {
      label: 'Labor Day',
      value: 'LABOR_DAY',
      showCountdown: true,
      eventStartDate: config.DATES.LABOR_DAY_START,
      isEarlySale: false,
      listingMode: `LABOR_DAY_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: LoyaltyIcon,
      iconName: 'LoyaltyIcon',
      shouldShowDeals: true,
      showSitePrefix: true,
      shouldOverrideCollageDailyImage: false
    };
  }

  const showBlackFridayEarlyDeals =
    config.SHOW_EARLY_DEALS && isBeforeBlackFriday() && !isCyberMonday();
  const shouldReturnBfObj =
    isBlackFriday() || isBlackFridayDealsWeek() || showBlackFridayEarlyDeals;

  if (shouldReturnBfObj) {
    return {
      label: isBlackFriday() ? 'Black Friday' : 'Black Friday Week',
      value: 'BLACK_FRIDAY',
      shouldShowDeals: isBlackFridayDealsWeek() || isBlackFriday(),
      showCountdown: true,
      showSnowFall: true,
      showBannerConfetti: false,
      overrideBannerBgColor: '#0F8A5F',
      shouldShowStrategyChips: isBlackFridayDealsWeek() || isBlackFriday(),
      // overrideBannerBgColor: '#ff6101',
      eventStartDate: config.DATES.BLACK_FRIDAY_WEEK_START,
      isEarlySale: isBeforeBlackFriday(),
      saleLink: config.EVENT_LINKS.BLACK_FRIDAY,
      affiliateTag: config.AFFILIATE_TAGS.BLACK_FRIDAY_WEEK,
      overrideChipLabel: isBeforeBlackFriday()
        ? 'Early Black Friday'
        : 'Black Friday',
      overrideChipColor: '#000000',
      overrideChipSelectedColor: '#FFFFFF',
      overrideChipColorDarkMode: '#FFFFFF',
      overrideChipSelectedColorDarkMode: '#FFFFFF',
      updatePageDealItemsForAllPageDeals: isBlackFriday(),
      overrideEmailEventLink: isBlackFriday()
        ? `https://www.jungle.deals/black-friday`
        : undefined,
      recheckStagedDeals: isBlackFriday(),
      updateEligiblePageDealOnceEvery30Mins: isBlackFriday(),
      overrideEmailLinksNumber: isBlackFriday() ? 20 : 4,
      shouldShowAPINotice: isBlackFriday(),
      overrideRibbonLabel: 'Black Friday',
      listingMode: `BLACK_FRIDAY_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: SellIcon,
      iconName: 'SellIcon',
      showSitePrefix: !isBeforeBlackFriday(),
      shouldOverrideCollageDailyImage: !isBeforeBlackFriday(),
      // Don't make this too long
      prefix: 'Black Friday',
      showNavLinkOption: isBlackFriday() || isBlackFridayDealsWeek(),
      navLinkTitle: isBlackFridayDealsWeek()
        ? 'Black Friday Week'
        : 'Black Friday',
      navLinkHref: '/black-friday',
      showRibbonsAtFullOpacity: isBlackFriday(),
      copyLinkPrefix: isBlackFridayDealsWeek()
        ? isBlackFriday()
          ? 'Amazon Black Friday: '
          : 'Black Friday Week: '
        : undefined,
      emailPrefix: isBlackFridayDealsWeek()
        ? isBlackFriday()
          ? 'Black Friday'
          : 'Black Friday Week'
        : undefined,
      showGiftGuideLinks: isBlackFriday() || isBlackFridayDealsWeek(),
      customLoadingMessages:
        isBlackFridayDealsWeek() || isBlackFriday()
          ? customBlackFridayLoadingMessages
          : undefined,
      shouldShowGiftGuidesInEmail: true,
      frontPageTitle: isBlackFriday()
        ? 'Hand Picked Black Friday Deals'
        : 'Hand Picked Black Friday Week Deals',
      startEmailMessage:
        isBlackFridayDealsWeek() || isBlackFriday()
          ? blackFridayDeal
          : undefined
    };
  }

  if (isCyberMonday()) {
    return {
      label: 'Cyber Monday',
      // This is due to the overlap of Black Friday and Cyber Monday
      value: 'BLACK_FRIDAY',
      showSnowFall: true,
      overrideBannerBgColor: '#CC231E',
      showCountdown: false,
      eventStartDate: config.DATES.CYBER_MONDAY_START,
      saleLink: config.EVENT_LINKS.CYBER_MONDAY,
      shouldShowStrategyChips: true,
      isEarlySale: false,
      recheckStagedDeals: true,
      updatePageDealItemsForAllPageDeals: true,
      updateEligiblePageDealOnceEvery30Mins: true,
      overrideEmailLinksNumber: 20,
      shouldShowAPINotice: true,
      affiliateTag: config.AFFILIATE_TAGS.CYBER_MONDAY_WEEK,
      overrideEmailEventLink: `https://www.jungle.deals/cyber-monday`,
      // This is due to the overlap of Black Friday and Cyber Monday
      listingMode: `BLACK_FRIDAY_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      frontPageTitle: 'Hand Picked Cyber Monday Deals',
      Icon: SellIcon,
      iconName: 'SellIcon',
      shouldShowDeals: true,
      showSitePrefix: true,
      prefix: 'Cyber Monday',
      shouldOverrideCollageDailyImage: true,
      showNavLinkOption: true,
      navLinkTitle: 'Cyber Monday',
      navLinkHref: '/cyber-monday',
      showRibbonsAtFullOpacity: true,
      copyLinkPrefix: 'Amazon Cyber Monday: ',
      emailPrefix: 'Cyber Monday',
      showGiftGuideLinks: true,
      customLoadingMessages: customCyberMondayLoadingMessages,
      shouldShowGiftGuidesInEmail: true,
      startEmailMessage: `
        <div style="font-style:italic;font-size: 14px;line-height:18px;">
        <p style="margin-bottom:12px">It's Cyber Monday and the savings are bigger than ever! Head over to <a href="${config.SITE_URL}/cyber-monday" target="_blank">Amazon's Cyber Monday Deals</a> to discover incredible discounts on tech, home essentials, fashion, beauty, and much more.</p>
        
        <p style="margin-bottom:12px">Amazon is rolling out massive savings on thousands of products TODAY ONLY, with many deals available for a limited time or while supplies last. Be sure to catch those Lightning Deals before they’re gone, and check back frequently for new products added!</p>
        
        <p style="margin-bottom:12px">We’ve curated the best Cyber Monday offers to make your shopping simple and stress-free. Don’t miss your chance to score big—shop now and get your holiday shopping done with unbeatable deals!</p>
        
        <p style="margin-bottom:12px"><a href="${config.SITE_URL}/cyber-monday" target="_blank">Shop Today's Best Cyber Monday Deals</a></p>
      </div>
    `
    };
  }

  if (displayTwelveDaysOfDeals()) {
    return {
      label: 'Twelve Days of Deals',
      value: 'TWELVE_DAYS_OF_DEALS',
      showSnowFall: true,
      showCountdown: true,
      shouldShowStrategyChips: true,
      overrideBannerBgColor: '#235E6F',
      eventStartDate: config.DATES.TWELVE_DAYS_OF_DEALS_START,
      overrideCountdownMessage: `Twelve Days of Deals: Day ${getDaysBetweenNowAndDate(
        config.DATES.TWELVE_DAYS_OF_DEALS_START
      )} of 12`,
      saleLink: config.EVENT_LINKS.TWELVE_DAYS_OF_DEALS,
      isEarlySale: false,
      listingMode: `TWELVE_DAYS_OF_DEALS_${currentYear}`,
      dealHighlightColor: colors.jdbPurple,
      chipSelectedColor: colors.primeSelected,
      Icon: CelebrationIcon,
      iconName: 'CelebrationIcon',
      shouldShowDeals: false,
      showSitePrefix: false,
      shouldOverrideCollageDailyImage: false,
      showGiftGuideLinks: true,
      customLoadingMessages: holidayLoadingMessages,
      shouldShowGiftGuidesInEmail: true
    };
  }

  if (displayShippingCutoffMessage()) {
    return {
      shouldShowStrategyChips: false,
      // https://www.amazon.com/b/?node=122248329011&tag=jd-gg-20
      label: 'Shipping Cutoff',
      // This is due to the overlap of Black Friday and Cyber Monday
      value: 'SHIPPING_CUTOFF',
      overrideCountdownMessage: 'Shipping cutoff for last-minute gifts:',
      showSnowFall: true,
      overrideBannerBgColor: '#CC231E',
      showCountdown: true,
      eventStartDate: config.DATES.SHIPPING_CUTOFF_MESSAGE_END,
      saleLink: config.EVENT_LINKS.SHIPPING_CUTOFF,
      isEarlySale: false,
      affiliateTag: config.AFFILIATE_TAGS.GIFT_GUIDE,
      listingMode: `SHIPPING_CUTOFF_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: SellIcon,
      iconName: 'SellIcon',
      shouldShowDeals: false,
      showSitePrefix: false,
      shouldOverrideCollageDailyImage: false,
      showGiftGuideLinks: true,
      customLoadingMessages: lastMinuteLoadingMessages,
      shouldShowGiftGuidesInEmail: true,
      startEmailMessage: `
        <div style="font-style:italic;font-size: 14px;line-height:18px;">
          <p style="margin-bottom:12px">It's the final countdown to the holidays! Filter by our <a href="${config.SITE_URL}" target="_blank">Gift Guides</a> to find the perfect presents for everyone on your list.</p>
        </div>
        `
    };
  }

  return null;
};

// used on backend for future product database additions
// if the asin exists in the event deals database table
export const getFutureEventListingMode = (): string => {
  if (isBeforePrimeDay() || isPrimeDay() || config.IS_PRIME_RUN_UP) {
    return `PRIME_DAY_${currentYear}`;
  }

  if (isBeforeLaborDay() || isLaborDay()) {
    return `LABOR_DAY_${currentYear}`;
  }

  if (
    isBeforeBlackFriday() ||
    isBlackFridayDealsWeek() ||
    isBlackFriday() ||
    isCyberMonday()
  ) {
    return `BLACK_FRIDAY_${currentYear}`;
  }

  return 'UNKNOWN';
};

// used for day of database additions for listing more
export const getCurrentEventListingMode = (): string => {
  // if something is added on prime day, just count it as a prime day product
  if (isPrimeDay()) {
    return `PRIME_DAY_${currentYear}`;
  }

  return 'UNKNOWN';
};

// used for staging the listing mode for the event
export const hasFutureOrCurrentEvent = (): boolean => {
  return (
    isBeforeLaborDay() ||
    isBeforePrimeDay() ||
    isPrimeDay() ||
    isLaborDay() ||
    isBlackFriday() ||
    isBlackFridayDealsWeek() ||
    isCyberMonday() ||
    isBeforeBlackFriday() ||
    isBeforeCyberMonday()
  );
};
