import React from 'react';
import config from '@configFile';
import { SentMailing } from '@api/database/models/sentMailing';

const getName = (name: string, tagline: string) => {
  return (
    <span>
      <strong>{name}</strong>
      {tagline}
    </span>
  );
};

export const allMailingLists = [
  {
    id: config.sendGrid.mailingLists.test,
    displayName: 'Test',
    displayDescription:
      'This is a test email list that I use to test out new features',
    name: 'Test - This is a test email list that I use to test out new features',
    labelName: 'Test deals',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.testDeals,
    isHidden: true,
    defaultCheckedForSignUp: false,
    signUpPrompt: {
      title: 'Sign up to get these deals into your inbox once a day at 6am PST',
      subtitle:
        "No need to keep checking back! We'll send these deals to you once a day to keep you updated!"
    }
  },
  {
    id: 'ab5c4530-caa4-44a4-9ce4-ea329a3113c3',
    name: 'Test Non test',
    displayName: 'Test Non test',
    displayDescription:
      'This is a test email list that I use to test out new features',
    labelName: 'Test non test',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.testDeals,
    isHidden: true,
    defaultCheckedForSignUp: false,
    signUpPrompt: {
      title: 'Sign up to get these deals into your inbox once a day at 6am PST',
      subtitle:
        "No need to keep checking back! We'll send these deals to you once a day to keep you updated!"
    }
  },
  {
    id: config.sendGrid.mailingLists.dailyDeals,
    isPopular: true,
    name: getName(
      '⭐ Most Popular ⭐ — Daily Deal Email',
      ' - This will be sent out daily around 6am PST'
    ),
    displayName: 'Daily Deal Email',
    displayDescription:
      'This will is sent out daily around 6am PST containing the most popular deals of the past day, the newest high value coupons, promotions and more.',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.dailyDeals,
    labelName: 'Daily Deal Email',
    defaultCheckedForSignUp: true,
    signUpPrompt: {
      title: 'Sign up to get these deals into your inbox once a day at 6am PST',
      subtitle:
        "No need to keep checking back! We'll send these deals to you once a day to keep you updated!"
    }
  },
  {
    id: config.sendGrid.mailingLists.hotDeals,
    name: getName(
      'Hot Deals',
      ' - Sent out when we think the deal will sell out by the end of the day'
    ),
    displayName: 'Hot Deals',
    displayDescription:
      'Sent out when we think the deal will sell out by the end of the day. These are the hottest deals on the website.',
    labelName: 'Hot Deals',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.hotDeals,
    defaultCheckedForSignUp: true,
    signUpPrompt: {
      title: 'Get notified of the hottest deals before they sell out',
      subtitle:
        "No drawback to saving money! We'll send you an email when we think a deal will sell out by the end of the day."
    }
  },
  {
    id: config.sendGrid.mailingLists.promotions,
    name: getName(
      'Amazon Promotions',
      ' - Round ups curated by Jungle Deals staff sent around once a day'
    ),
    displayName: 'Amazon Promotions',
    displayDescription:
      'Round ups curated by Jungle Deals staff sent around once a day. Including round ups of useful items, Amazon promotions, and more.',
    labelName: 'Amazon Promotions',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.promotions,
    defaultCheckedForSignUp: true,
    signUpPrompt: {
      title:
        'Want us to email you when they are some great promotions available?',
      subtitle:
        'We will keep you updated about the hottest promotions on Amazon. We will send you a round up of the best promotions once a day.'
    }
  },
  {
    id: config.sendGrid.mailingLists.bestSellingTopDeals,
    name: getName(
      'Most Popular Deals of the Week',
      ' - Sent out once a week on Sunday'
    ),
    displayName: 'Most Popular Deals of the Week',
    displayDescription:
      'Sent out once a week on Sunday. These are the most popular deals of the week.',
    labelName: 'Most Popular Deals of the Week',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.bestSellingTopDeals,
    defaultCheckedForSignUp: true,
    signUpPrompt: {
      title:
        'Sign up to get the most popular deals in your inbox once a week on Sunday',
      subtitle:
        'Every Sunday you will get a list right into your email of the most popular deals of the week!'
    }
  },
  {
    id: config.sendGrid.mailingLists.babyDeals,
    name: getName(
      'Deals for Parents',
      ' - Sent when a great deal for your baby, toddler or kiddo comes up'
    ),
    displayName: 'Deals for Parents',
    displayDescription:
      'Sent when a great deal for your baby, toddler or kiddo comes up. These include diapers, toys, baby gear, kids gear and more for anyone under 12.',
    labelName: 'Deals for Parents',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.babyDeals,
    defaultChedefaultCheckedForSignUpcked: false,
    signUpPrompt: {
      title: 'Sign up to get these deals into your inbox once a day at 6am PST',
      subtitle:
        "No need to keep checking back! We'll send these deals to you once a day to keep you updated!"
    }
  },
  {
    id: config.sendGrid.mailingLists.dogDeals,
    name: getName(
      'Deals for Dog Owners',
      ' - Sent when a great deal for your doggo comes up along with a once weekly round up on Sunday'
    ),
    displayName: 'Deals for Dog Owners',
    displayDescription:
      'Sent when a great deal for your doggo comes up along with a once weekly round up on Sunday. Save on treats, food, dog bags, toys, clothes, medicines and more.',
    labelName: 'Deals for Dog Owners',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.dogDeals,
    defaultCheckedForSignUp: false,
    signUpPrompt: {
      title: 'Got a dog you want to stock up on Amazon Deals for?',
      subtitle:
        'Join our mailing list targeted at doggy parents and care givers. We will let you know when there are fantastic deals for your dog and send a once weekly Sunday summary right to your inbox.'
    }
  },
  {
    id: config.sendGrid.mailingLists.catDeals,
    name: getName(
      'Deals for Cat Owners',
      ' - Sent when a great deal for your feline comes up along with a once weekly round up on Sunday'
    ),
    displayName: 'Deals for Cat Owners',
    displayDescription:
      'Sent when a great deal for your feline comes up along with a once weekly round up on Sunday. Save on treats, food, cat litter, toys, medicines and more.',
    labelName: 'Deals for Cat Owners',
    unsubscribeGroup: config.sendGrid.unsubscribeGroups.catDeals,
    defaultCheckedForSignUp: false,
    signUpPrompt: {
      title: 'Cat treats on the cheap?',
      subtitle:
        'Join our mailing list targeted at cat parents and care givers. We will let you know when there are fantastic deals for your cat and send a once weekly Sunday summary right to your inbox.'
    }
  }
];

export const getMailingListById = (id: string) => {
  return allMailingLists.find((list) => list.id === id);
};

export const getMailingListLabelFromId = (id: string): string => {
  return getMailingListById(id)?.labelName || '';
};

export const getMailingListNameFromId = (id: string) => {
  return getMailingListById(id)?.name || '';
};

export const getMailingListDisplayNameFromId = (id: string) => {
  return getMailingListById(id)?.displayName || '';
};

export const emailListsNotHidden = allMailingLists.filter(
  (list) => !list.isHidden
);

export const filterOutTestEmailLists = (emailLists: SentMailing[]) => {
  return emailLists.filter(
    (list) => list.sentToList !== config.sendGrid.mailingLists.test
  );
};

export const allMailingListsOptions = allMailingLists.map((list) => ({
  name: list.labelName,
  value: list.id
}));

export default allMailingLists.filter((list) => !list.isHidden);
