import { DealPostType, PromotionDisplayType } from '@types';
import { getPromotionPageLinkFromPromotionLink } from '@api/emails/emailUtils';
import { formatPrice } from '../index';
import { getExtraDealConfig } from './map';

function extraDealHasCouponPost(deal: DealPostType): boolean {
  return !!(deal.couponFixed || deal.couponPercentage);
}

const getExtraDealText = (extraDeal: string): string => {
  return (extraDeal || '').toLowerCase().trim();
};

const roundToNearestTwoDecimals = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export const getExtraDeal = (
  deal: DealPostType
): Record<string, any> | null => {
  if (
    !deal.extraDeal ||
    extraDealHasCouponPost(deal) ||
    deal.category === 'Books'
  ) {
    return null;
  }

  const lowestExtraDeal = getExtraDealText(deal.extraDeal);
  const lowestExtraDealObj = getExtraDealConfig(lowestExtraDeal);

  // if extraDealMap[lowestExtraDeal] is an object
  const hasExtraDealLowestObj =
    lowestExtraDealObj &&
    typeof lowestExtraDealObj !== 'undefined' &&
    typeof lowestExtraDealObj === 'object';

  if (!hasExtraDealLowestObj) {
    return null;
  }

  if (
    lowestExtraDealObj.getX &&
    lowestExtraDealObj.forPriceOfY &&
    deal.extraDeal
  ) {
    const dealParams = {
      extraDeal: deal.extraDeal,
      getX: lowestExtraDealObj.getX,
      forPriceOfY: lowestExtraDealObj.forPriceOfY
    };

    // const buyXSaveYPrice = deal.finalPrice * dealParams.forPriceOfY;
    const multibuyDiscount = roundToNearestTwoDecimals(
      deal.currentPrice * (dealParams.getX - dealParams.forPriceOfY)
    );
    const maxSSDiscount = roundToNearestTwoDecimals(
      deal.currentPrice * dealParams.getX * ((deal.maxSs || 0) / 100)
    );
    const orderTotal = deal.currentPrice * dealParams.getX;
    const buyXSaveYPrice = orderTotal - multibuyDiscount - maxSSDiscount;

    let qty = lowestExtraDealObj.getX || 1;

    if (deal.qtyTerm && deal.qty) {
      qty = dealParams.getX * deal.qty;
    }

    const retObj: Record<string, any> = {
      extraDeal: deal.extraDeal,
      extraDealPrice: buyXSaveYPrice,
      eachPrice: buyXSaveYPrice / dealParams.getX,
      eachTermPrice: buyXSaveYPrice / qty,
      getX: dealParams.getX,
      forPriceOfY: dealParams.forPriceOfY,
      multibuyDiscount,
      maxSSDiscount:
        deal.currentPrice * dealParams.getX * ((deal.maxSs || 0) / 100)
    };

    if (deal.extraDealLink) {
      retObj.extraDealLink = deal.extraDealLink;
    }

    return retObj;
  }

  if (lowestExtraDealObj?.x && lowestExtraDealObj?.saveY && deal.extraDeal) {
    const dealParams = {
      extraDeal: deal.extraDeal,
      buyX: lowestExtraDealObj.x,
      saveY: lowestExtraDealObj.saveY
    };

    const maxSSDiscount = roundToNearestTwoDecimals(
      deal.currentPrice * dealParams.buyX * ((deal.maxSs || 0) / 100)
    );

    const buyXSaveYPrice =
      deal.currentPrice * dealParams.buyX - dealParams.saveY - maxSSDiscount;

    if (deal.qtyLimit && dealParams.buyX > deal.qtyLimit) {
      return null;
    }

    let qty = dealParams.buyX;

    if (deal.qtyTerm && deal.qty) {
      qty = dealParams.buyX * deal.qty;
    }

    const retObj = {
      extraDeal: deal.extraDeal,
      extraDealPrice: buyXSaveYPrice,
      eachPrice: buyXSaveYPrice / dealParams.buyX,
      eachTermPrice: buyXSaveYPrice / qty,
      x: dealParams.buyX,
      saveY: dealParams.saveY,
      maxSSDiscount
    } as any;

    if (deal.extraDealLink) {
      retObj.extraDealLink = deal.extraDealLink;
    }

    return retObj;
  }

  if (
    lowestExtraDealObj?.x &&
    lowestExtraDealObj?.percentOffOne &&
    deal.extraDeal
  ) {
    const { percentOffOne } = lowestExtraDealObj;
    const { x } = lowestExtraDealObj;
    const percentOverallDiscount = percentOffOne / x;

    const qty = deal.qty
      ? deal.qty * lowestExtraDealObj.x
      : lowestExtraDealObj.x;

    const totalBefore = deal.currentPrice * lowestExtraDealObj.x;
    const maxSSDiscount = roundToNearestTwoDecimals(
      deal.currentPrice * x * ((deal.maxSs || 0) / 100)
    );
    const totalDiscount = roundToNearestTwoDecimals(
      deal.currentPrice * lowestExtraDealObj.x * (percentOverallDiscount / 100)
    );
    const buyXSaveYPrice = totalBefore - totalDiscount - maxSSDiscount;

    const retObj = {
      extraDeal: deal.extraDeal,
      extraDealPrice: buyXSaveYPrice,
      eachPrice: buyXSaveYPrice / x,
      eachTermPrice: buyXSaveYPrice / qty,
      x,
      saveY: percentOverallDiscount,
      saveYDiscount: totalDiscount,
      maxSSDiscount
    } as any;

    return retObj;
  }

  return null;
};

const isPromotionExtraDealLinkApproved = (
  extraDealLink: string,
  approvedPromotions: PromotionDisplayType[]
): boolean => {
  return !!approvedPromotions.find(
    (promotion) => promotion.extraDealLink === extraDealLink
  );
};

const getSingleExtraDealLineEmail = (
  link: string,
  extraDeal: string,
  extraDealLink: string
): string => {
  return extraDeal && extraDealLink
    ? `<span style="display:block;font-style:italic;">*Also eligible for promotion <strong><a href="${link}">${extraDeal
        .trim()
        .toLowerCase()}</a></strong>!*</span>`
    : '';
};

const getComplexExtraDealLineEmail = ({
  link,
  eachTermPrice,
  extraDeal,
  extraDealPrice,
  termCount,
  qtyTerm,
  qty
}: {
  link: string;
  extraDeal: string;
  termCount: number;
  extraDealPrice: number;
  eachTermPrice: number;
  qtyTerm?: string;
  qty?: number | null;
  approvedPromotions: PromotionDisplayType[];
}) => {
  const qtyTermString =
    qtyTerm && qty ? ` or ${formatPrice(eachTermPrice / qty)}/${qtyTerm}` : '';
  return `<span style="display:block;font-style:italic;">
  *You could also take advantage of the <strong><a href="${link}">${extraDeal.toLowerCase()}</a></strong> with the final price of ${formatPrice(
    extraDealPrice
  )} for ${termCount} ${
    termCount === 1 ? 'order' : 'orders'
  }! <strong>That's as low as ${formatPrice(
    eachTermPrice
  )}/each${qtyTermString}!</strong>*
</span>`;
};

const getTermEachPrice = (objEachTermPrice: number, termCount: number) => {
  return objEachTermPrice && termCount > 1
    ? objEachTermPrice / termCount
    : objEachTermPrice;
};

export const getExtraMapperEmailLine = (
  deal: DealPostType,
  link: string,
  approvedPromotions: PromotionDisplayType[]
): string => {
  const extraDealObj = getExtraDeal(deal);
  const ret = '';

  const { extraDeal, extraDealLink, qtyTerm } = deal;

  if (
    !extraDealLink ||
    !isPromotionExtraDealLinkApproved(extraDealLink, approvedPromotions)
  ) {
    return ret; // Return immediately if promotion is not approved or not extra deal link
  }

  if (!extraDealObj) {
    // If no extraDealObj but there's an extraDeal and extraDealLink, return a simple promotion line
    return getSingleExtraDealLineEmail(link, extraDeal, extraDealLink);
  }

  // Construct the line based on the existence of x or getX in extraDealObj
  const termCount = extraDealObj.x || extraDealObj.getX || 0;

  return getComplexExtraDealLineEmail({
    link:
      getPromotionPageLinkFromPromotionLink(
        extraDealLink,
        approvedPromotions
      ) || link,
    extraDeal: extraDealObj.extraDeal,
    termCount,
    extraDealPrice: extraDealObj.extraDealPrice,
    eachTermPrice: getTermEachPrice(extraDealObj.extraDealPrice, termCount),
    qtyTerm,
    qty: deal?.qty || null,
    approvedPromotions
  });
};
